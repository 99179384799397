import { Button, Modal } from "rsuite";

type ConsentTextModalProps = {
  show: boolean;
  onHide: () => void;
};

const ConsentTextModal = ({ show, onHide }: ConsentTextModalProps) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "justify" }} className="modalBody">
        <h6
          style={{ marginBlockEnd: 15, textAlign: "center" }}
          className="modalHeader"
        >
          SMARTOFFICE AKILLI TELEFON UYGULAMASI KAPSAMINDA KİŞİSEL VERİLERİN
          İŞLENMESİNE İLİŞKİN AÇIK RIZA METNİ
        </h6>
        <p>
          <b>SMARTOFFICE YÖNETİM VE DANIŞMANLIK LTD. ŞTİ.</b> olarak Kişisel
          Verilerin Korunması mevzuatı kapsamında istisnaya giren (örneğin
          kanunlarda öngörülmesi veya sözleşmenin ifası için gerekli olması)
          haller haricinde, kişisel verilerinizin işlenmesi ve/veya aktarılması
          kapsamında aşağıdaki hususlara ilişkin açık rızanızı talep ediyoruz:
        </p>
        <p>
          <b>Kullanıcı: SMARTOFFICE YÖNETİM VE DANIŞMANLIK LTD. ŞTİ.</b>’nin
          6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca; sözleşme
          süreçlerinin yürütülmesi, talep/şikayetlerin takibi, fiziksel mekan
          güvenliğinin sağlanması ve mal/hizmet satış süreçlerinin yürütülmesi
          faaliyetleri uyarınca kimlik, iletişim, lokasyon, özlük, finans,
          müşteri işlem ve işlem güvenliği kategorisindeki kişisel verilerimin
          Smartoffice Akıllı Telefon Uygulaması Kapsamında Kişisel Verilerin
          İşlenmesine İlişkin Aydınlatma Metni’nde{" "}
          <b>belirtilen amaç ve kapsam uyarınca</b> işlenmesine, muhafaza
          edilmesine ve yurtdışına aktarılmasına özgür irademle rıza
          gösterdiğimi beyan ederim.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} appearance="subtle">
          Kapat / Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConsentTextModal;
