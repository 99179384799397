import { Formik } from "formik";
import * as Yup from "yup";
import { Button, FlexboxGrid, Modal } from "rsuite";
import { Input } from "../../../components";

type OrderFormModalProps = {
  show: boolean;
  onHide: () => void;
};

const OrderFormModal = ({ show, onHide }: OrderFormModalProps) => {
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      "Bu alan zorunludur* / This field is required*"
    ),
    surname: Yup.string().required(
      "Bu alan zorunludur* / This field is required*"
    ),
    company: Yup.string().required(
      "Bu alan zorunludur* / This field is required*"
    ),
    email: Yup.string()
      .email("Lütfen geçerli bir email giriniz* / Please enter a valid email*")
      .required("Bu alan zorunludur* / This field is required*"),
    phone: Yup.string()
      .phone(
        "",
        true,
        "Lütfen geçerli bir telefon numarası giriniz* / Please enter a valid phone number*"
      )
      .required("Bu alan zorunludur* / This field is required*"),
  });
  const onSubmit = ({
    name,
    surname,
    company,
    email,
    phone,
  }: {
    name: string;
    surname: string;
    company: string;
    email: string;
    phone: string;
  }) => {};
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Teklif Al / Offer</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          name: "",
          surname: "",
          company: "",
          email: "",
          phone: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          values,
        }) => (
          <form
            action={
              !errors.company &&
              !errors.email &&
              !errors.name &&
              !errors.phone &&
              !errors.surname &&
              touched.company &&
              touched.email &&
              touched.name &&
              touched.phone &&
              touched.surname
                ? "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                : undefined
            }
            method={
              !errors.company &&
              !errors.email &&
              !errors.name &&
              !errors.phone &&
              !errors.surname &&
              touched.company &&
              touched.email &&
              touched.name &&
              touched.phone &&
              touched.surname
                ? "POST"
                : undefined
            }
          >
            {!errors.company &&
              !errors.email &&
              !errors.name &&
              !errors.phone &&
              !errors.surname &&
              touched.company &&
              touched.email &&
              touched.name &&
              touched.phone &&
              touched.surname && (
                <>
                  <input type="hidden" name="oid" value="00D20000000CpJZ" />
                  <input
                    type="hidden"
                    name="retURL"
                    value="https://soweb.komutteknolojisi.com/thank-you"
                  />
                </>
              )}
            {/* <input type="hidden" name="debug" value={1} />
                <input
                  type="hidden"
                  name="debugEmail"
                  value="serap.koprulu@smartoffice.com.tr"
                /> */}
            ​
            <Modal.Body>
              <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={11}>
                  <Input
                    id="first_name"
                    label={"Adınız / Name"}
                    name="first_name"
                    value={values.name}
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    error={errors.name}
                    touched={touched.name}
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={11}>
                  <Input
                    id="last_name"
                    label={"Soydınız / Surname"}
                    name="last_name"
                    value={values.surname}
                    onChange={handleChange("surname")}
                    onBlur={handleBlur("surname")}
                    error={errors.surname}
                    touched={touched.surname}
                  />
                </FlexboxGrid.Item>
              </FlexboxGrid>
              <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={11}>
                  <Input
                    id="company"
                    label={"Şirket / Company"}
                    name="company"
                    value={values.company}
                    onChange={handleChange("company")}
                    onBlur={handleBlur("company")}
                    error={errors.company}
                    touched={touched.company}
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={11}>
                  <Input
                    id="phone"
                    label={"Telefon / Phone"}
                    name="phone"
                    value={values.phone}
                    onChange={handleChange("phone")}
                    onBlur={handleBlur("phone")}
                    error={errors.phone}
                    touched={touched.phone}
                  />
                </FlexboxGrid.Item>
              </FlexboxGrid>
              <Input
                id="email"
                label={"Email"}
                name="email"
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                error={errors.email}
                touched={touched.email}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                onClick={() => handleSubmit()}
                appearance="primary"
              >
                Gönder / Submit
              </Button>
              <Button onClick={onHide} appearance="subtle">
                İptal / Cancel
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default OrderFormModal;
