import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ReactWhatsapp from "react-whatsapp";
import AwesomeSlider from "react-awesome-slider";
//@ts-ignore
import withAutoplay from "react-awesome-slider/dist/autoplay";
import { Alert, Divider } from "rsuite";
import "yup-phone";

import "react-awesome-slider/dist/styles.css";
import "rsuite/dist/styles/rsuite-default.css";
import "./style.css";

import { images } from "../../assets";
import {
  ConsentTextModal,
  OrderFormModal,
  PersonalDataModal,
} from "./components";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const BaseScreen = () => {
  const sliderRef = useRef();
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const [consentTextModalVisibility, setConsentTextModalVisibility] =
    useState<boolean>(false);
  const [personalDataModalVisibility, setPersonalDataModalVisibility] =
    useState<boolean>(false);
  const [landscapeScreen, setLandscapeScreen] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile && window.matchMedia("(orientation: portrait)").matches) {
      setLandscapeScreen(true);
    }
  }, []);

  const resizeFunction = () => {
    if (isMobile && window.matchMedia("(orientation: portrait)").matches) {
      setLandscapeScreen(true);
    } else setLandscapeScreen(false);
  };

  window.onresize = resizeFunction;

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/aydinlatma-metni") setPersonalDataModalVisibility(true);
    if (pathname === "/acik-riza-metni") setConsentTextModalVisibility(true);
    if (pathname === "/thank-you")
      Alert.success(
        "İletişim bilgileriniz başarılı bir şekilde alındı. Yakında sizinle iletişime geçeceğiz. / Succesfully acquired your contact informations. We will contact with you soon.",
        15000
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const close = () => {
    setShow(false);
  };

  return (
    <>
      {landscapeScreen && (
        <img
          style={{
            width: "100%",
            position: "absolute",
            zIndex: 2500,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src={images.landscapeMode}
          alt=""
        />
      )}

      <OrderFormModal show={show} onHide={close} />
      <ConsentTextModal
        show={consentTextModalVisibility}
        onHide={() => setConsentTextModalVisibility(false)}
      />
      <PersonalDataModal
        show={personalDataModalVisibility}
        onHide={() => setPersonalDataModalVisibility(false)}
      />
      <AutoplaySlider
        ref={sliderRef}
        animation="foldOutAnimation"
        fillParent={true}
        buttons={true}
        bullets={false}
        play={true}
        cancelOnInteraction={false}
        interval={7000}
        media={[
          {
            source: "background.png",
          },
          {
            source: "Akasya.jpg",
          },
          {
            source: "Akasya-2.JPG",
          },
          {
            source: "Akasya-3.jpg",
          },
          {
            source: "Akmerkez.jpg",
          },
          {
            source: "Akmerkez-2.jpg",
          },
          {
            source: "bodrum-1.jpg",
          },
          {
            source: "bodrum-3.jpg",
          },
          {
            source: "bulvar-1.JPG",
          },
          {
            source: "bulvar-2.jpg",
          },
          {
            source: "Bulvar-3.JPG",
          },
          {
            source: "bulvar-4.jpg",
          },
          {
            source: "emaar.JPG",
          },
          {
            source: "emaar-2.JPG",
          },
          {
            source: "Fişekhane-1.JPG",
          },
          {
            source: "Fişekhane-2.jpg",
          },
          {
            source: "Kağıthane-Premier.JPG",
          },
          {
            source: "Kahaan-1.JPG",
          },
          {
            source: "Kahaan-2.JPG",
          },
          {
            source: "Levent195-1.jpg",
          },
          {
            source: "Levent195-2.jpg",
          },
          {
            source: "MaslakNo1.JPG",
          },
          {
            source: "MaslakNo1-2.jpg",
          },
          {
            source: "Nida-Kozyatağı-1.JPG",
          },
          {
            source: "Nida-Kozyatağı-2.JPG",
          },
          {
            source: "VekoGiz.JPG",
          },
          {
            source: "VekoGiz-2.JPG",
          },
        ]}
      />
      <div className="backgroundShadow" />
      <div
        style={{
          width: "100%",
          height: "50%",
          backgroundColor: "transparent",
          position: "relative",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "93%", height: "70%" }}>
          <div className="dividingDiv">
            <div style={{ width: "40%" }}>
              <img
                src={images.logoWithName}
                alt=""
                style={{
                  maxWidth: 700,
                  width: "100%",
                }}
              />
            </div>

            <div
              className="addresses"
              style={{ textAlign: "end", lineHeight: 1.4 }}
            >
              <div>BÜYÜKYALI FİŞEKHANE</div>
              <span>ETİLER AKMERKEZ</span>
              <div>KAĞITHANE PREMIER KAMPÜS OFİS</div>
              <span>LEVENT BÜYÜKDERE 195 PLAZA</span>
              <div>LEVENT NİDA KULE</div>
              <span>MASLAK NO:1</span>
              <div>MASLAK VEKO GİZ</div>
              <span>TAKSİM KAHAN</span>
              <br />
              <br />
              <div>ACIBADEM AKASYA</div>
              <span>ATAŞEHİR BULVAR 216</span>
              <div>KOZYATAĞI NİDA KULE</div>
              <span>ÜSKÜDAR EMAAR</span>
              <br />
              <br />
              <div>BODRUM BİTEZ</div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "50%",
          backgroundColor: "transparent",
          position: "relative",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingBlockEnd: 20,
        }}
      >
        <div>
          <div className="offerButton" onClick={() => setShow(true)}>
            Teklif Al / Offer
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  paddingInlineEnd: "4%",
                  width: "100%",
                  textAlign: "end",
                  marginBlockStart: "4%",
                  marginBlockEnd: "1%",
                }}
              >
                <a href="tel:08503992999" className="phoneNumber">
                  0850 399 29 99
                </a>
              </div>
              <div
                className="socialIconsDiv"
                style={{
                  display: "flex",
                  paddingInlineEnd: "4%",
                }}
              >
                <ReactWhatsapp number="+905494049358">
                  <img className="socialIcon" src={images.whatsapp} alt="" />
                </ReactWhatsapp>
                <a
                  href="https://instagram.com/smartofficetr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="socialIcon" src={images.instagram} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/smartoffice"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="socialIcon" src={images.linkedin} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="constructionDiv">
            <div style={{ margin: 0, padding: 0 }}>Our website is</div>
            <span style={{ margin: 0, padding: 0 }}>under construction</span>
          </div>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              className="textDiv"
              onClick={() => setConsentTextModalVisibility(true)}
            >
              AÇIK RIZA METNİ
            </div>
            <Divider vertical />
            <div
              className="textDiv"
              onClick={() => setPersonalDataModalVisibility(true)}
            >
              AYDINLATMA METNİ
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseScreen;
