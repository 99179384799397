import {
  ControlLabel,
  Icon,
  Input as InputRS,
  InputGroup,
  InputNumber,
  InputProps,
} from "rsuite";
import "./style.css";

export enum InputType {
  default,
  number,
}

const Input = ({
  numberSelect,
  inputType,
  children,
  error,
  touched,
  ...rest
}: Props) => {
  const inputStyles = () => {
    switch (inputType) {
      case InputType.number:
        return (
          //@ts-ignore
          <InputNumber
            min={0}
            defaultValue={rest.defaultValue ? rest.defaultValue : "0"}
            {...rest}
          />
        );
      case InputType.default:
      default:
        return <InputRS {...rest} />;
    }
  };
  return (
    <div style={{ marginBlockEnd: 15 }}>
      {rest.label && (
        <ControlLabel
          style={{
            paddingBlockStart: 2,
            marginBlockEnd: 4,
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          {rest.label}
        </ControlLabel>
      )}
      <InputGroup className={error && touched ? "errorInput" : "defaultInput"}>
        {inputStyles()}
        {error && touched && (
          <InputGroup.Addon style={{ backgroundColor: "transparent" }}>
            <Icon icon="exclamation-triangle" />
          </InputGroup.Addon>
        )}
        {!error && touched && (
          <InputGroup.Addon style={{ backgroundColor: "transparent" }}>
            <Icon icon="check" />
          </InputGroup.Addon>
        )}
        {children || null}
      </InputGroup>
      {error && touched && <p className="inputErrorStyles">{error}</p>}
    </div>
  );
};

interface Props extends InputProps {
  inputType?: InputType;
  children?: React.ReactNode;
  error?: string | null;
  touched?: boolean | null;
}

export default Input;
