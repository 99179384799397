import { Button, Modal } from "rsuite";

type PersonalDataModalProps = {
  show: boolean;
  onHide: () => void;
};

const PersonalDataModal = ({ show, onHide }: PersonalDataModalProps) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ textAlign: "justify", paddingInlineEnd: 10 }}
        className="modalBody"
      >
        <h6
          style={{ marginBlockEnd: 15, textAlign: "center" }}
          className="modalHeader"
        >
          SMARTOFFICE AKILLI TELEFON UYGULAMASI KAPSAMINDA KİŞİSEL VERİLERİN
          İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ
        </h6>
        <p>
          <b>Veri Sorumlusu: SMARTOFFICE YÖNETİM VE DANIŞMANLIK LTD. ŞTİ.</b>
        </p>
        <p>
          <b>SMARTOFFCE YÖNETİM VE DANIŞMANLIK LTD. ŞTİ. </b>
          olarak kişisel verilerinizin korunmasına ve güvenliğine en yüksek
          derecede özen göstermekteyiz. Buna göre, başta özel hayatın gizliliği
          olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel
          verilerin korunması amacıyla düzenlenen 6698 sayılı Kişisel Verilerin
          Korunması Kanunu (“KVKK veya Kanun”) kapsamında, kişisel verilerinizin
          toplanma şekilleri, işlenme amaçları, işlemenin hukuki nedenleri ve
          haklarınıza ilişkin olarak Veri Sorumlusu sıfatıyla sizleri işbu
          Aydınlatma Metni kapsamında bilgilendirmek isteriz:{" "}
        </p>
        <p>
          <b>1- İşlenen Kişisel Verileriniz:</b>
        </p>
        <p>
          Veri Sorumlusu olarak aşağıda belirttiğimiz kişisel verilerinizi
          işlemekteyiz:
        </p>
        <ul>
          <li>
            <b>Kimlik:</b> İsim-Soy isim bilgisi
          </li>
          <li>
            <b>İletişim:</b> Telefon numarası, e-posta adresi
          </li>
          <li>
            <b>Lokasyon:</b> Konum bilgisi
          </li>
          <li>
            <b>Özlük:</b> Giriş-çıkış kayıtlarınız, ofis kullanımı tarih ve
            süreleriniz
          </li>
          <li>
            <b>Finans:</b> Kredi kartı bilgileriniz
          </li>
          <li>
            <b>Müşteri İşlem:</b> Sipariş geçmişiniz, kredi bilgileriniz,
            şikayet ve talepleriniz
          </li>
          <li>
            <b>İşlem Güvenliği:</b> Telefon işletim sistemi ve sürümü, telefon
            türü, telefon ID no, uygulama üzerindeki işlem kayıtları, şifre
            bilgileri
          </li>
        </ul>
        <p>
          Belirtilmelidir ki; konum bilgileriniz Smartoffice kapı girişlerinde
          bulunan QR KOD uygulamasının çalışması ve ofis/toplantı odalarında yer
          alan havalandırma-aydınlatma fonksiyonu uygulama üzerinden
          kullanabilmeniz için toplanmaktadır. Bu kapsamda kapı girişlerindeki
          QR KOD görselini okuttuğunuz sırada ilgili lokasyonda olup
          olmadığınızı teyit etmek için mevcut bulunduğunuz lokasyon bilgisi
          toplanmakta olup aynı şekilde ofis ve toplantı odalarındaki
          havalandırma-aydınlatma fonksiyonu aktive etmek istediğiniz hallerde
          de uygulama aynı şekilde mevcut lokasyon bilginizi telefonunuzun arka
          planında toplamaktadır. Ayrıca; uygulamamız üzerinden herhangi bir
          hassas verinizi işlememekteyiz.
        </p>
        <p>
          <b>2- Kişisel Verilerin İşlenme Amacı:</b>
        </p>
        <p>
          İşbu Aydınlatma Metni kapsamında işlenen kişisel verileriniz; sözleşme
          süreçlerinin yürütülmesi, talep/şikayetlerin takibi, fiziksel mekan
          güvenliğinin sağlanması ve mal/hizmet satış süreçlerinin yürütülmesi
          amacıyla işlenmektedir. Aydınlatma Metni’nde belirtilen Kişisel
          Verilerinizin işlenme amacında herhangi bir değişiklik olması halinde,
          ayrıca tarafınızdan izin alınacaktır.
        </p>
        <p>
          <b>
            3- Kişisel Verilerin Aktarılabileceği Taraflar ile Aktarım Amacı:
          </b>
        </p>
        <p>
          KVKK’nın 8. ve 9. maddesinde kişisel verilerin aktarılması
          çerçevesinde kişisel verileriniz; sözleşme süreçlerinin yürütülmesi,
          talep/şikayetlerin takibi, fiziksel mekan güvenliğinin sağlanması ve
          mal/hizmet satış süreçlerinin yürütülmesi amacıyla yazılımın
          entegrasyonunu sağlayan veri işleyen sıfatıyla yurtdışında yerleşik
          olan Saleforce.com, Inc. şirketine aktarılmaktadır.
        </p>
        <p>
          <b>4- Toplama Yöntemi ve Hukuki Sebebi</b>
        </p>
        <p>
          Kişisel verileriniz, işbu Aydınlatma Metni’nde belirtilen amaçlar
          doğrultusunda kullanılmak üzere, elektronik ortamlarda toplanmaktadır.
          Bu kapsamda; KVKK’nın 5. ve 6. maddelerinde belirtilen kişisel veri
          işleme şartlarına dayalı olarak; yukarıda 1. madde kapsamında
          belirtilen veri işleme amaçları çerçevesince kişisel verileriniz;
          md.5/1 uyarınca açık rızanızın alınması hukuki sebebi uyarınca halinde
          işlenecektir.
        </p>
        <p>
          <b>5- Kişisel Veri Sahibinin Hakları</b>
        </p>
        <p>
          Kanun’un 11. maddesi uyarınca, veri sahibi olarak;
          <br /> Kişisel verilerinin işlenip işlenmediğini öğrenme,
          <br /> Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
          <br /> Kişisel verilerinin işlenme amacını ve bunların amacına uygun
          kullanılıp kullanılmadığını öğrenme,
          <br /> Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
          üçüncü kişileri bilme,
          <br /> Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
          bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
          verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
          <br /> KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
          olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması
          hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu
          kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere
          bildirilmesini isteme,
          <br /> İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
          analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
          çıkmasına itiraz etme, Kişisel verilerinin kanuna aykırı olarak
          işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini
          talep etme, haklarına sahipsiniz.
          <br />
          <br /> KVKK madde 13 /1 uyarınca, işbu maddede belirtilen haklarınızı
          kullanmak için talebinizi, yazılı olarak{" "}
          <a href="mailto:www.smartoffice.com.tr">www.smartoffice.com.tr</a> web
          adresindeki başvuru formu ve kimliğinizi teşvik edici belgeleri
          eksiksiz doldurarak İstanbul adresine bizzat elden veya noter
          vasıtasıyla Maslak Mah. Meydan Sok. No.3 İç Kapı No:45
          Sarıyer/İstanbul adresine veya wecare@smartoffice.com.tr iletilmesi
          halinde 30 (otuz) gün içerisinde başvurunuz değerlendirilerek
          sonuçlandırılacaktır.
          <br />
          <br /> Taleplere ilişkin olarak yazılı şekilde yanıt verilmesi ve bu
          sebeple ayrıca bir maliyet söz konusu olması halinde, Kişisel Verileri
          Koruma Kurulu tarafından belirlenen tarifeye göre tarafınızdan ücret
          talep edilebilecektir.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} appearance="subtle">
          Kapat / Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PersonalDataModal;
